<template>
  <div :id="id" class="relative">
    <h5 v-if="label" class="text-subhead-3 mb-4 text-black-80">{{ label }}</h5>
    <div
      class="flex w-full items-center justify-between"
      :class="[vertical ? 'flex-col' : 'flex-row ', dense ? 'gap-3' : 'gap-6']"
    >
      <Component
        :is="slots.label ? 'div' : 'label'"
        v-for="item in items"
        :key="item.value"
        :for="item.value"
        class="flex flex-row flex-wrap items-center justify-start gap-[10px]"
        :class="[vertical ? 'w-full' : 'w-1/2', slots.label ? '' : 'cursor-pointer']"
      >
        <input
          :id="item.value"
          v-model="vModel"
          :value="item.value"
          type="radio"
          :name="item.value"
          :disabled="disabled"
          class="relative float-left size-5 appearance-none rounded-full border-[1.6px] border-solid border-black-100 transition-all duration-200 before:pointer-events-none before:absolute before:ml-[-3.5px] before:mt-[-3.5px] before:size-6 before:bg-transparent before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:size-[10px] after:rounded-full after:content-[''] checked:border-primary-100 checked:outline-success-100 checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:size-[10px] checked:after:rounded-full checked:after:border-primary-100 checked:after:bg-primary-100 checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:border-primary-100 hover:bg-primary-05 hover:checked:border-primary-120 hover:checked:bg-primary-05 hover:checked:after:bg-primary-120 focus-visible:border-primary-50 focus-visible:bg-primary-05 focus-visible:shadow-none focus-visible:outline-none focus-visible:before:scale-100 focus-visible:before:border-[1.6px] focus-visible:before:border-solid focus-visible:before:border-success-100 focus-visible:before:opacity-100 focus-visible:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus-visible:border-primary-50 checked:focus-visible:after:bg-primary-50 active:border-primary-120 active:bg-primary-10 checked:active:border-primary-120 checked:active:bg-white checked:active:after:bg-primary-120 disabled:border-black-30 disabled:bg-black-05 disabled:ring-0 disabled:checked:after:bg-black-30 disabled:hover:border-black-30"
          :class="inputClass"
          @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        />
        <small :for="item.value">
          <slot name="label" :item>{{ item.text }}</slot>
        </small>
        <slot name="custom" :item></slot>
      </Component>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Record<string, any>">
const emits = defineEmits(['update:modelValue'])

type Props = {
  id: string
  modelValue: any
  items: T[]
  label?: string
  disabled?: boolean
  vertical?: boolean
  inputClass?: string
  dense?: boolean
}

const slots = defineSlots<{
  label?(props: { item: T }): void
  custom?(props: { item: T }): void
}>()

const props = withDefaults(defineProps<Props>(), {
  label: '',
  inputClass: '',
  dense: true,
})

const vModel = useVModel(props, 'modelValue', emits)
</script>

<style scoped></style>
