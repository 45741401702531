<template>
  <div :class="fullWidth ? 'w-full' : 'w-fit'">
    <UiTooltip name="call_disabled" :class="fullWidth ? 'w-full' : 'w-fit'">
      <template #activator>
        <div>
          <UiButtonBase
            id="call"
            :disabled="isDisabledCallButton"
            class="!gap-x-0 !px-0"
            :class="[
              fullWidth ? 'w-full' : 'w-fit',
              {
                '!border-black-40 !bg-black-40 !text-white': isDisabledCallButton,
              },
            ]"
          >
            <UiTooltip name="call_info" class="size-full">
              <template #activator>
                <div
                  class="flex size-full items-center justify-center gap-x-1 px-2"
                  @click="isMobile ? showCallMethodsPopup() : callFromMainNumber()"
                >
                  <UiIcon name="call-filled"></UiIcon>
                  <span>Call</span>
                </div>
              </template>
              <template v-if="!isMobile" #content>Click to call from CRM to main number</template>
            </UiTooltip>
            <UiTooltip v-if="!isMobile" name="call_methods" class="h-full">
              <template #activator>
                <UiInputCustomMenu
                  id="showMethods"
                  ref="showMethodsRef"
                  name="showMethods"
                  class="h-full"
                  align-right
                  :width="300"
                >
                  <template #activator="{ onClick, isOpen }">
                    <button
                      class="relative h-full px-2 before:absolute before:inset-y-2 before:left-0 before:w-[1.5px] before:rounded-full before:bg-white"
                      @click="onClick"
                    >
                      <UiIcon
                        name="chevron-big-filled-down"
                        :class="[{ 'rotate-180': isOpen }, 'transition-all duration-200']"
                      />
                    </button>
                  </template>
                  <template #content>
                    <div class="flex flex-col gap-4 px-2 py-4">
                      <div>
                        <p class="text-caption mb-2 text-center text-black-60">call from</p>
                        <div class="flex rounded-xl bg-black-03 p-0.5 text-black-70">
                          <button
                            v-for="item in callWays"
                            :key="item.value"
                            class="text-subhead-2 w-full p-2"
                            :class="
                              callWay === item.value
                                ? 'text-subhead-1 rounded-[10px] bg-secondary-50 text-black-100 shadow'
                                : 'text-subhead-2'
                            "
                            @click="callWay = item.value"
                          >
                            {{ item.text }}
                          </button>
                        </div>
                      </div>
                      <div v-if="localNumbers.length">
                        <UiInputSelectSimpleItem
                          v-for="(item, index) in callMethods"
                          :key="`${item.value}_${index}`"
                          :model-value="callMethod"
                          :shallow-value="callMethod"
                          :item="item"
                          @input="callMethod = $event"
                        />
                      </div>
                      <UiButtonBase
                        id="call_method"
                        class="w-full"
                        type="secondary"
                        @click=";[(countCallPopup = 0), chooseMethod()]"
                      >
                        <UiIcon name="call" />
                        Start call
                      </UiButtonBase>
                    </div>
                  </template>
                </UiInputCustomMenu>
              </template>
              <template #content>Choose a calling method</template>
            </UiTooltip>
          </UiButtonBase>
        </div>
      </template>
      <template v-if="!callEnabled" #content
        >Your telephony is currently disabled. Contact your manager to enable it.</template
      >
    </UiTooltip>
    <LeadCallOutbound
      v-if="showCall"
      :key="Number(showCall)"
      :lead="lead"
      :local-number="randomLocalNumber"
      :full-phone
      :call-way="callWay"
      @terminated="callTerminated"
      @get-call-id="handleCallId"
      @called="showOutboundOutcome"
      @no-answer="checkShowingCallAgainPopup"
    />
  </div>
</template>

<script setup lang="ts">
import sample from 'lodash/sample'
import { driver, type Driver } from 'driver.js'
import { POPUPS } from '@/components/dynamic/maps'
import { CUSTOM_EVENTS, STAGES, PERMISSIONS } from '@/constants'
import type { CallId, InputItem, Lead } from '@/types'
import { useAuthStore } from '@/store/auth'
import { useUiStore } from '@/store/ui'
import 'driver.js/dist/driver.css'

const driverObj = ref<Driver | null>(null)

const MISSED_CALLS_LIMIT = 5
const SHOW_CALL_OUTCOME_POPUP = 'pbx_show_popup_after_call'
const DURATION_CALL_OUTCOME_POPUP = 'pbx_show_popup_after_call_if_duration_more_than'

const authStore = useAuthStore()
const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'called', 'call-started'])

type Props = {
  modelValue: Lead
  isMobile: boolean
  fullWidth?: boolean
  disabled?: boolean
  updateLead?: boolean
}

const props = defineProps<Props>()

const lead = useVModel(props, 'modelValue', emits)

const showMethodsRef = ref<HTMLElement | null>(null)
const callMethod = ref('main')
const callWay = useCookie('callWay', { default: () => 'crm' })
const randomLocalNumber = ref<string | undefined>('')
const countCallPopup = ref(0)
const localNumbers = ref([])
const callEnabled = authStore.getUser.is_voice_call_enabled
const showCall = ref(false)
const callId = ref<CallId>(null)
const goStage = useCookie('goStage')

const minTimeForShowingShortOutcome = ref(90)
const needShowOutboundOutcomePopup = ref(false)
const needShowBlurStage = computed(() =>
  [STAGES.NEW, STAGES.NO_ANSWER, STAGES.CALL_LATER, STAGES.DORMANT, STAGES.INTERESTED].includes(lead.value.stage.code!)
)

const isSDRTourActive = inject<Ref<boolean>>('isSDRTourActive')
const isPropertiesOffersTourActive = inject<Ref<boolean>>('isPropertiesOffersTourActive')
const isPhoneWayPartner = inject<Ref<boolean>>('isPhoneWayPartner')
const isPhoneWayRepresentative = inject<Ref<boolean>>('isPhoneWayRepresentative')

const fullPhone = computed(() => {
  if (isPhoneWayPartner?.value) {
    return `${lead.value.partner?.phone_country?.phone_code}${lead.value.partner?.phone}`
  }

  if (isPhoneWayRepresentative?.value) {
    return `${lead.value.representative?.phone_country.phone_code}${lead.value.representative.phone}`
  }

  return `${lead.value.phone_country.phone_code}${lead.value.phone}`
})

onNuxtReady(async () => {
  if (!isSDRTourActive?.value && !isPropertiesOffersTourActive?.value) {
    const { phones } = await useGetLocalNumbers(props.modelValue.id)
    localNumbers.value = phones
    if (lead.value.missed_calls_count >= MISSED_CALLS_LIMIT && lead.value.stage.code === STAGES.NEW) {
      showUnsuccessfulContactPopup()
    }
  }

  await getOutcomeSettings()
})

const getOutcomeSettings = async () => {
  const [
    {
      settings: { pbx_show_popup_after_call: showCallOutcomePopup },
    },
    {
      settings: { pbx_show_popup_after_call_if_duration_more_than: durationCallOutcome },
    },
  ] = await Promise.all([
    useGetSettingsByCode(SHOW_CALL_OUTCOME_POPUP),
    useGetSettingsByCode(DURATION_CALL_OUTCOME_POPUP),
  ])
  needShowOutboundOutcomePopup.value = !!+showCallOutcomePopup
  minTimeForShowingShortOutcome.value = +durationCallOutcome
}
const handleCallId = (id: CallId) => {
  callId.value = id
}

const callMethods: InputItem[] = [
  {
    value: 'main',
    text: 'Main number',
  },
  {
    value: 'local',
    text: 'Alternative number',
  },
]

const callWays = [
  {
    value: 'crm',
    text: 'CRM',
  },
  {
    value: 'phone',
    text: 'IP Phone',
  },
]

const isDisabledCallButton = computed(() => {
  if (!callEnabled || !authStore.getIsActiveForOutboundCall || showCall.value || props.disabled) {
    return true
  }

  return false
})

const callFromMainNumber = () => {
  countCallPopup.value = 0
  callMethod.value = 'main'
  callWay.value = 'crm'
  randomLocalNumber.value = ''
  call()
}

const call = () => {
  if (props.updateLead) {
    emits('call-started')
  }
  if (!authStore.getIsActiveForOutboundCall) return
  showCall.value = true
}

const chooseMethod = () => {
  if (callMethod.value === 'main') {
    if (randomLocalNumber.value) randomLocalNumber.value = ''
  } else {
    const random = sample(localNumbers.value)
    randomLocalNumber.value = random?.replace('_', '')
  }
  call()
  showMethodsRef.value?.closeMenu()
}

const showCallAgainPopup = () => {
  uiStore.showPopup(POPUPS.LEAD_CALL_AGAIN, undefined, { input: () => callAgain() })
}

const showCallMethodsPopup = () => {
  uiStore.showPopup(
    POPUPS.LEAD_CALL_METHODS,
    { callMethods },
    {
      input: (method: string) => {
        callWay.value = 'crm'
        callMethod.value = method
        countCallPopup.value = 0
        chooseMethod()
      },
    }
  )
}

const checkShowingCallAgainPopup = () => {
  callTerminated(true)

  // If lead no answer will increase count missing call
  setTimeout(() => {
    emits('update:modelValue', { ...props.modelValue, missed_calls_count: ++lead.value.missed_calls_count })
  }, 1100)

  if (countCallPopup.value >= 1 || !localNumbers.value.length) return

  showCallAgainPopup()
}

const callAgain = () => {
  callMethod.value = callMethod.value === 'main' ? 'local' : 'main'
  countCallPopup.value++
  chooseMethod()
}

const isAgentAndIsFirstCall = computed(() => {
  return !props.modelValue.has_calls && authStore.getIsAgent
})

const updateHistory = () => {
  setTimeout(() => {
    document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))
    if (isAgentAndIsFirstCall.value) emits('update:modelValue', { ...props.modelValue })
  }, 1100)
}

const callTerminated = (refresh: boolean) => {
  showCall.value = false

  if (refresh) {
    updateHistory()
  }
  if (props.updateLead) {
    document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_DATA))
  }
}

const showOverlay = () => {
  goStage.value = 'true'

  driverObj.value = driver({
    overlayColor: '#0D0F15',
    overlayOpacity: 0.4,
    disableActiveInteraction: true,
    onHighlightStarted: () => {
      setTimeout(() => {
        goStage.value = null

        if (driverObj.value) {
          driverObj.value.destroy()
        }
      }, 2000)
    },
  })

  setTimeout(() => {
    driverObj.value?.highlight({
      element: '#change-stage',
      popover: {
        description: 'Don’t forget to change lead stage.',
        side: 'top',
        align: 'start',
        popoverClass: '!p-2',
      },
    })
  }, 500)
}

const handleOutcome = () => {
  if (needShowBlurStage.value && !props.isMobile) {
    showOverlay()
  }
  callId.value = null
  callTerminated(true)
}

const handlePostClose = () => document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))

const showUnsuccessfulContactPopup = () => {
  uiStore.showPopup(POPUPS.PIPELINE_LEAD_UNSUCCESSFUL_CONTACT, { lead }, { input: () => handlePostClose() })
}

const showOutboundOutcome = (duration: number) => {
  if (
    useHasPermissions([PERMISSIONS.DISPLAY_CALL_OUTCOME_POPUP]) &&
    needShowOutboundOutcomePopup.value &&
    (duration < minTimeForShowingShortOutcome.value || callWay.value === 'crm')
  ) {
    const fullOutcome = duration < minTimeForShowingShortOutcome.value
    if (props.isMobile) {
      emits('called', { callId: callId.value, fullOutcome, showStage: needShowBlurStage.value })
    } else {
      uiStore.showPopup(
        POPUPS.LEAD_CALL_OUTBOUND_OUTCOME,
        {
          callId,
          fullOutcome,
        },
        { input: () => handleOutcome() }
      )
    }
  }
}
</script>

<style scoped></style>
