<template>
  <li :id="`activity-${modelValue.id}-${pinView}`" class="group">
    <span
      class="absolute -left-4 hidden size-8 items-center justify-center rounded-full bg-primary-05 ring-8 ring-white"
      :class="{ 'sm:flex': !pinView }"
    >
      <UiIcon
        :name="itemsTypeMap.get(modelValue.type.code)?.icon || 'info-circle'"
        size="xs"
        class="hidden text-primary-100 sm:block"
      ></UiIcon>
    </span>

    <div
      ref="timelineItem"
      class="relative rounded-xl border border-solid border-black-10 p-4 transition-colors duration-500 sm:rounded-2xl"
      :class="[{ 'bg-primary-05': !isItemViewed }, { 'sm:ml-8': !pinView }]"
    >
      <div class="flex items-center gap-x-2">
        <p v-if="modelValue.created_at" class="text-caption flex flex-row items-center gap-1 py-1 text-black-70">
          <span v-if="isToday(new Date(modelValue.created_at))">Today |</span>
          {{ format(new Date(modelValue.created_at), `d MMMM yyyy, HH:mm`) }}
        </p>
        <div
          v-if="modelValue.type.code === 'call'"
          class="flex h-6 w-fit flex-row items-center justify-between gap-1 gap-x-2 rounded-lg px-2 py-0.5 sm:h-8 sm:py-1"
          :class="
            modelValue.direction === 'inbound'
              ? 'bg-additional-2-10 text-additional-2-120'
              : 'bg-primary-10 text-primary-100'
          "
        >
          <UiIcon v-if="modelValue.direction === 'inbound'" name="call-inbound" size="xs" />
          <UiIcon v-else name="call-outgoing" :size="isMobile ? 'xxs' : 'xs'" />
          <h5 class="text-subhead-4 sm:text-subhead-3">
            {{ modelValue.direction === 'inbound' ? 'Incoming' : 'Outgoing' }}
          </h5>
        </div>
      </div>

      <div :class="{ 'pt-3': !itemsTypeMap.get(modelValue.type.code)?.hasActions }">
        <component
          :is="itemsTypeMap.get(modelValue.type.code)?.component"
          v-if="itemsTypeMap.get(modelValue.type.code)"
          :model-value="modelValue"
          @update:model-value="emits('update:modelValue', $event)"
        ></component>
      </div>
      <UiTooltip v-if="showPin" :name="`pin_disabled-${modelValue.id}-${pinView}`" class="absolute right-4 top-4">
        <template #activator>
          <button
            :id="`pin-${modelValue.id}`"
            class="rounded-md text-black-70 opacity-0 transition-all hover:bg-primary-10 group-hover:opacity-100 group-[.driver-active-element]:!text-primary-100 group-[.driver-active-element]:!opacity-100 [&.driver-active-element]:!opacity-100"
            :class="[
              { '!text-primary-100 opacity-100': modelValue.is_pinned },
              { 'pointer-events-none !text-black-30': disablePin && !modelValue.is_pinned },
            ]"
            :disabled="updatingActivity"
            @click="pinActivity"
          >
            <UiIcon v-if="modelValue.is_pinned" name="pin-filled" class="text-primary-100" />
            <UiIcon v-else name="pin" />
          </button>
        </template>
        <template v-if="disablePin && !modelValue.is_pinned" #content
          >You can have only 5 pinned notes at once. Please unpin one to add extra.</template
        >
      </UiTooltip>
    </div>
  </li>
</template>

<script setup lang="ts">
import { format, isToday } from 'date-fns'
import type { Component } from 'nuxt/schema'
import { useUiStore } from '~/store/ui'
import type { TimelineItem } from '@/types'

const uiStore = useUiStore()
const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')

const emits = defineEmits(['update:modelValue', 'update:pin'])

type Props = {
  modelValue: TimelineItem
  module: 'lead' | 'deal'
  canViewHighlightedTimeline?: boolean
  pinView?: boolean
  disablePin?: boolean
  showPin?: boolean
}
const props = defineProps<Props>()

const vModel = defineModel<TimelineItem>('modelValue', {
  required: true,
})

type Item = {
  icon: string
  component: Component
  hasActions?: boolean
  viewMode?: boolean
}

const leadTypesMaps = new Map<string, Item>([
  [
    'referral_comment',
    { icon: 'edit-page', component: resolveComponent('UiTimelineItemLeadReferralComment') as Component },
  ],
  [
    'referral_note',
    { icon: 'edit-page', component: resolveComponent('UiTimelineItemLeadReferralNote') as Component, viewMode: true },
  ],
  ['note', { icon: 'edit-page', component: resolveComponent('UiTimelineItemLeadNote') as Component, hasActions: true }],
  [
    'referral_info_updated',
    {
      icon: 'edit-page',
      component: resolveComponent('UiTimelineItemLeadReferralInfoUpdated') as Component,
      viewMode: true,
    },
  ],
  ['call', { icon: 'call', component: resolveComponent('UiTimelineItemLeadCall') as Component }],
  ['information', { icon: 'info-circle', component: resolveComponent('UiTimelineItemLeadInformation') as Component }],
  [
    'transition',
    { icon: 'operations-forward', component: resolveComponent('UiTimelineItemLeadTransition') as Component },
  ],
  [
    'calendar_task',
    { icon: 'calendar', component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component },
  ],
  [
    'calendar_meeting',
    { icon: 'calendar', component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component },
  ],
  [
    'calendar_call',
    { icon: 'calendar', component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component },
  ],
  [
    'calendar_all_day',
    { icon: 'calendar', component: resolveComponent('UiTimelineItemLeadCalendarActivity') as Component },
  ],
  ['lead_changes', { icon: 'info-circle', component: resolveComponent('UiTimelineItemLeadChanges') as Component }],
])

const dealTypesMap = new Map<string, Item>([
  [
    'transition',
    { icon: 'operations-forward', component: resolveComponent('UiTimelineItemDealTransition') as Component },
  ],
  [
    'note',
    {
      icon: 'info-circle',
      component: resolveComponent('UiTimelineItemDealNote') as Component,
      hasActions: true,
    },
  ],
  [
    'info_change',
    {
      icon: 'edit-page',
      component: resolveComponent('UiTimelineItemDealInfoChange') as Component,
    },
  ],
])

const modules = new Map<string, Map<string, Item>>([
  ['lead', leadTypesMaps],
  ['deal', dealTypesMap],
])

const itemsTypeMap = modules.get(props.module) || leadTypesMaps

const currentItem = computed(() => itemsTypeMap.get(props.modelValue.type.code))

const isItemViewed = computed(() => (isViewMode.value ? props.modelValue.is_viewed : true))

const isViewMode = computed(() => currentItem.value?.viewMode && props.canViewHighlightedTimeline)

const timelineItem = ref(null)

const updatingActivity = ref(false)

const { stop } = useIntersectionObserver(
  isViewMode.value ? timelineItem : null,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      useViewActivity({
        id: props.modelValue.id,
        isViewed: true,
      }).then(({ data }) => {
        vModel.value.is_viewed = Boolean(data.value?.data.is_viewed)
        stop()
      })
    }
  },
  {
    threshold: 0.3,
  }
)

onMounted(() => {
  if (!isViewMode.value) {
    stop()
  }
})

const pinActivity = async () => {
  try {
    updatingActivity.value = true
    const value = !props.modelValue.is_pinned
    await useUpdateLeadActivity(props.modelValue.id, { is_pinned: value })
    vModel.value = { ...vModel.value, is_pinned: value }
    emits('update:pin', vModel.value)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    updatingActivity.value = false
  }
}
</script>

<style scoped></style>
