import type { Lead } from '@/types'
import { PERMISSIONS } from '@/constants'

import { useUiStore } from '@/store/ui'
import { useAuthStore } from '@/store/auth'

export default (lead: Lead): Boolean => {
  const authStore = useAuthStore()
  const uiStore = useUiStore()

  return uiStore.getLeadPhoneHide && authStore.getIsAgent && !lead.has_calls && !lead.reporter
}

export const useGetShowPhoneLead = (lead?: Lead): Boolean => {
  const authStore = useAuthStore()
  return (
    useHasPermissions([PERMISSIONS.SEE_CONTACT_DETAILS_LEADS]) ||
    useHasPermissions([PERMISSIONS.SEE_CONTACT_DETAILS_TEAM_LEADS]) ||
    (useHasPermissions([PERMISSIONS.SEE_CREATED_LEADS]) && lead?.reporter?.id === authStore.getUser.id)
  )
}
