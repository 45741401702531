<template>
  <div class="text-body-2 flex flex-col gap-1">
    <span class="text-caption-2 text-black-60">{{ label }}</span>
    <div
      class="flex flex-row items-center gap-2"
      :class="{
        'select-none blur-sm': phone?.toString() === HIDDEN || useGetPhoneHide(lead),
      }"
    >
      <UiFlagIcon v-if="phoneCountry" :name="phoneCountry.iso2.toLowerCase()" />
      <span>
        {{ phoneCountry?.name }}
      </span>
      <span>+{{ phoneCountry?.phone_code }} </span>
      <span>
        {{ phone }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Lead } from '~/types/leads'
import { HIDDEN } from '~/constants'
import type { Country } from '~/types/libraries'

type Props = {
  phone?: number | string | null
  phoneCountry: Country | null
  label: string
  lead: Lead
}
defineProps<Props>()

const { smaller } = useBreakpoints(useGetBreakpoints())

const isMobile = smaller('xs')
</script>
