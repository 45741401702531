<template>
  <div>
    <div class="mt-2 flex flex-col gap-2">
      <div class="flex flex-wrap gap-x-2 gap-y-1">
        {{ additionalItems[0].value.split('resolution ')[0] }}
        <span class="bg-secondary-20 px-1">{{ resolution }}</span>
      </div>
      <div v-if="modelValue.comment">
        <span class="text-caption-2 text-black-40">Additional note for meeting outcome</span>
        <p>{{ modelValue.comment }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const additionalItems = props.modelValue.additional?.filter((item) => item.value)

const resolution = computed(() => {
  return props.modelValue.additional[0].value.split('resolution ')[1]
})
</script>

<style scoped></style>
