export const useGetWAChatMessages = async (chatId: string, older_than_date?: string) => {
  const { data, error } = await useAPIFetch(() => `/wa/chats/${chatId}/messages`, { params: { limit : 20, page: 1, older_than_date } })
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useGetWALeadChats = async (leadId: number) => {
  const data = await useAPIFetch(() => `/wa/leads/${leadId}/chats`)
  return data as any
}

export const useWAMarkLeadChatAsRead = async (leadId: number) => {
  const { data, error } = await useAPIFetch(() => `/wa/leads/${leadId}/notifications/read`, {
    method: 'PATCH',
  })
  if (error.value) throw createError(error.value)
  return data as any
}
