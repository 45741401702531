<template>
  <ul
    class="flex w-full items-start justify-start rounded-xl bg-black-03 p-0.5 text-black-100 transition-opacity duration-200"
    :class="{ 'opacity-50': disabled }"
  >
    <li
      v-for="(tab, index) in tabs"
      :id="tab.id"
      :key="tab.id"
      class="relative flex h-9 w-full cursor-pointer items-center justify-center py-[10px] transition-all duration-200"
      :tabindex="0"
      :class="[
        {
          'rounded-[10px] bg-secondary-50 shadow': tab.id === modelValue || tab.subIds?.includes(modelValue),
          'after:absolute after:right-0 after:h-[20px] after:w-px after:bg-black-40 last:after:opacity-0': showBorder(
            tab,
            index
          ),
          '!cursor-default': tab.disabled || disabled,
        },
      ]"
      @keydown.enter="selectTab(tab)"
      @click="selectTab(tab)"
    >
      <template v-if="tab.component">
        <component
          :is="tab.component.component"
          v-model="modelValue"
          :disabled
          @click.stop
          v-on="tab.component?.events"
        />
      </template>
      <h5 v-else :class="tab.id !== modelValue ? 'text-subhead-2 text-black-70' : 'text-subhead-1 text-black-100'">
        {{ tab.text }}
      </h5>
      <p
        v-if="tab.count"
        class="text-caption ml-1 flex size-6 items-center justify-center rounded-full bg-black-100 p-1 text-white"
      >
        {{ tab.count }}
      </p>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { Tab } from '@/types'

const emits = defineEmits(['update:modelValue'])

const modelValue = defineModel<string>()

const route = useRoute()

const showBorder = (tab: Tab, index: number) => {
  const activeIndex = props.tabs.findIndex((t) => t.id === modelValue.value)
  return tab.id !== modelValue.value && !tab.subIds?.includes(modelValue.value!) && index !== activeIndex - 1
}

type Props = {
  tabs: Tab[]
  useRoute?: boolean
  disabled?: boolean
}

const props = defineProps<Props>()

if (props.useRoute) {
  watch(
    () => route.hash,
    () => {
      if (route.hash && props.tabs.find((t) => t.id === route.hash.split('#')[1])) {
        emits('update:modelValue', route.hash.split('#')[1])
      } else {
        emits('update:modelValue', props.tabs[0].id)
      }
    }
  )
}

onNuxtReady(() => {
  if (props.useRoute) {
    if (route.hash && props.tabs.find((t) => t.id === route.hash.split('#')[1])) {
      emits('update:modelValue', route.hash.split('#')[1])
    } else {
      emits('update:modelValue', props.tabs[0].id)
    }
  } else if (!modelValue.value) {
    emits('update:modelValue', props.tabs[0].id)
  }
})

const selectTab = (tab: Tab) => {
  if (tab.disabled || props.disabled) return
  emits('update:modelValue', tab.id)
  // don't replace with router so it doesn't scroll on mobile
  if (props.useRoute) history.replaceState(history.state, '', `#${tab.id}`)
}
</script>

<style scoped></style>
